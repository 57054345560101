import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Wrapper from '../wrapper'
import Seemore from '../../components/seemore'

const WhoTeam = ({ members, content }) => {
  const sortTeam = members.teamMembers.sort((a, b) => a.displayOrder - b.displayOrder);

  return (
    <Wrapper grid>
      <div className="who__team row">
        {sortTeam.map((item, index) => (
          // Team Members
          <div className="who__team__member
                          col-xs-12
                          col-sm-6
                          col-md-6
                          col-lg-4"
               data-id={item.id}
               key={`team__member-${index}`}
          >
            <GatsbyImage alt={item.name} image={item.photo.gatsbyImageData} imgStyle={{ objectFit: 'cover' }} />
            <h4>{item.name}</h4>
            {!!item.description && (
              <h2>{item.description}</h2>
            )}
          </div>
        ))}
        <div className="who__jobentry
                        col-xs-12
                        col-sm-6
                        col-md-6
                        col-lg-4"
        >
          <div className="who__jobentry">
            <div className="who__jobentry__inner">
              <h3 dangerouslySetInnerHTML={{__html: content.headline}}/>
            </div>
            <h4>{content.question}</h4>
            <Seemore content={{link: content.link, textLink: content.textLink}}
                     rotated/>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default WhoTeam
