import React from "react";
import _flattendeep from "lodash.flattendeep";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop/src/TypistLoop";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import Wrapper from "../wrapper";

export default class WhoHero extends React.Component {
  constructor(props) {
    super(props);

    this.content = props.content;
    this.adjectives = _flattendeep(
      props.content.adjectives.map((adj) => adj.name)
    );
  }

  render() {
    return (
      <Wrapper grid>
        <div className="who__hero row">
          <div className="who__hero__header col-md-6 col-lg-4">
            <div className="who__hero__header__wrapper">
              <GatsbyImage
                alt={this.content.headline}
                image={this.content.headerImage.gatsbyImageData}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-7 offset-lg-1">
            <h1>{this.content.headline} </h1>
            <h1 className="who__hero__adjectives">
              <TypistLoop interval={250}>
                {this.adjectives.map((adj, index) => {
                  return (
                    <Typist key={`adjective-${index}`} startDelay={1000}>
                      {adj}
                      <Typist.Backspace count={adj.length} delay={1000} />
                    </Typist>
                  );
                })}
              </TypistLoop>
            </h1>

            <section
              className="who__hero__paragraph paragraph"
              dangerouslySetInnerHTML={{ __html: this.content.paragraph }}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}
