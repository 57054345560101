import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../layouts/index'
import Seo from '../components/seo'
import Wrapper from '../components/wrapper'
import WhoHero from '../components/who_we_are/who_hero'
import WhoQuote from '../components/who_we_are/who_quote'
import WhoShowcase from '../components/who_we_are/who_showcase'
import WhoTeam from '../components/who_we_are/who_team'

const WhoWeAre = ({ data, path, pageContext }) => {
  const team = data.datoCmsWhoWeAre.team.find(el => el.model.apiKey === 'team_content');

  return (
    <Layout path={path} locale={pageContext.locale} absolutePath={pageContext.absolutePath}>
      <Seo
        slug={data.datoCmsWhoWeAre.slug.link}
        content={data.datoCmsWhoWeAre.seo}
        lang={pageContext.locale}
        publication={data.datoCmsWhoWeAre.meta}
        keywords={team.teamMembers.map((item) => item.name).join(', ')}
      />
      <React.Fragment>
        <Wrapper color="yellow">
          <WhoHero content={data.datoCmsWhoWeAre} />
        </Wrapper>
        <Wrapper>
          <WhoQuote content={data.datoCmsWhoWeAre} />
          <WhoShowcase content={data.datoCmsWhoWeAre.showcase} />
          <WhoTeam members={team}
                   content={data.datoCmsWhoWeAre.team.find(el => el.model.apiKey === 'job_entry')} />
        </Wrapper>
      </React.Fragment>
    </Layout>
  )
}

export default WhoWeAre
export const query = graphql`
  query WhoWeAreQuery($locale: String!) {
    datoCmsWhoWeAre(locale: {eq: $locale}) {
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      seo {
        title
        description
        image {
          url
          height
          width
        }
        twitterCard
      }
      headerImage {
        alt
        gatsbyImageData(layout: CONSTRAINED)
      }
      slug {
        link
      }
      headline
      adjectives {
        name
      }
      paragraph
      statement
      statementTextLink
      statementLink {
        link
        locale
      }
      showcase {
        alt
        gatsbyImageData(layout: CONSTRAINED)
      }
      team {
        ... on DatoCmsTeamContent {
          id
          model {
            apiKey
          }
          teamMembers {
            id
            name
            description
            displayOrder
            photo {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        ... on DatoCmsJobEntry {
          id
          model {
            apiKey
          }
          question
          textLink
          link {
            link
            locale
          }
          headline
        }
      }
    }
  }
`
