import React from 'react'
import Flickity from "react-flickity-component";
import { GatsbyImage } from "gatsby-plugin-image";
// Components
import Wrapper from '../wrapper'

const WhoShowcase = ({ content }) => (
  <Wrapper>
    <Flick content={content} />
  </Wrapper>
)

export const Flick = ({ content }) => {
  const flickityOptions = {
    initialIndex: 1,
    accessibility: false,
    freeScroll: true,
    freeScrollFriction: 0.03,
    friction: 0.2,
    cellAlign: 'center',
    prevNextButtons: false,
    pageDots: false,
  }

  return (
    <Flickity options={flickityOptions} reloadOnUpdate>
      {content.map((item, index) => (
        <div className="image__container" key={`showcase-${index}`}>
          <GatsbyImage data-id={item.id} alt={item.alt || ''} image={item.gatsbyImageData} imgStyle={{ objectFit: 'contain'}} />
        </div>
      ))}
    </Flickity>
  )
}

export default WhoShowcase
