import React from 'react'

// Components
import Wrapper from '../wrapper'
import Seemore from '../../components/seemore'

const WhoQuote = ({ content }) => (
  <Wrapper grid>
    <div className="who__quote row justify-content-center">
      <div className="col-lg-10">
        <h3 dangerouslySetInnerHTML={{__html: content.statement}} />
        <Seemore content={{
                    textLink: content.statementTextLink,
                    link: content.statementLink
                 }}
                 rotated/>
      </div>
    </div>
  </Wrapper>
)

export default WhoQuote
